.main-message {
    font-family: 'Inter', sans-serif;
    background-color: white;
    color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.main-message a {
    color: blue;
    text-decoration: none;
}

.main-message a:hover {
    text-decoration: underline;
}